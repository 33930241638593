import {
  DashboardOutlined,
  AppstoreOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  FundOutlined,
  UserOutlined,
  SafetyOutlined,
  RadarChartOutlined,
  BarChartOutlined,
  TableOutlined,
  FolderOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: 'sidenav.dashboard.default',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        title: 'sidenav.dashboard.analytic',
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: 'sidenav.dashboard.sales',
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appsNavTree = [
  {
    key: 'apps',
    path: `${APP_PREFIX_PATH}/apps`,
    title: 'sidenav.apps',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'apps-dashboard',
        path: `${APP_PREFIX_PATH}/apps/dashboard`,
        title: 'sidenav.dashboard.default',
        icon: BarChartOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-project',
        path: `${APP_PREFIX_PATH}/apps/project`,
        title: 'sidenav.apps.board',
        icon: FolderOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-active-sprint',
        path: `${APP_PREFIX_PATH}/apps/active-sprint`,
        title: 'sidenav.apps.activesprint',
        icon: SlidersOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-sprint',
        path: `${APP_PREFIX_PATH}/apps/sprint`,
        title: 'sidenav.apps.sprints',
        icon: TableOutlined,
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: 'apps-mail',
      //   path: `${APP_PREFIX_PATH}/apps/mail`,
      //   title: 'sidenav.apps.mail',
      //   icon: MailOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      // {
      //   key: 'apps-chat',
      //   path: `${APP_PREFIX_PATH}/apps/chat`,
      //   title: 'sidenav.apps.chat',
      //   icon: MessageOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'apps-calendar',
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        title: 'sidenav.apps.calendar',
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-user',
        path: `${APP_PREFIX_PATH}/apps/user`,
        title: 'sidenav.apps.user',
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-role',
        path: `${APP_PREFIX_PATH}/apps/role`,
        title: 'sidenav.apps.role',
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: 'apps-tracking',
      //   path: `${APP_PREFIX_PATH}/apps/tracking`,
      //   title: 'sidenav.apps.tracking',
      //   icon: RadarChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const navigationConfig = [...appsNavTree];

export default navigationConfig;
